@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(./Resources/Fonts/Inter/static/Inter-Regular.ttf);
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: Inter, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #aeaeae;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  color: #00a9a5;
}
